import ContactBlock from '@uikit/components/ContactBlock/ContactBlock';
import type { EcgContactBlockProps } from './types';

const EcgContactBlock = ({
  firstName,
  lastName,
  pronouns,
  email,
  image,
  jobTitle,
  phone,
}: EcgContactBlockProps) => (
  <ContactBlock
    title={`${firstName} ${lastName}`}
    pronouns={pronouns}
    description={jobTitle}
    email={email}
    phone={phone}
    image={image}
  />
);

export default EcgContactBlock;
