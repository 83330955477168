import NewFooter from '../NewFooter/NewFooter';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import { ReactComponent as LinkedInLogo } from './assets/linkedin-full-logo.svg';
import { EcgFooterProps } from './types';

const EcgFooter = ({ skewOverlap }: EcgFooterProps) => {
  return (
    <NewFooter
      copyrightText="enercity Contracting GmbH"
      foundationSlot={
        <>
          <NewFooter.Link href="/impressum">Impressum</NewFooter.Link>
          <NewFooter.Link href="/datenschutz">Datenschutz</NewFooter.Link>
          <NewFooter.Link href="/seitenuebersicht">
            Seitenübersicht
          </NewFooter.Link>
        </>
      }
      logoSlot={<ProjectLogo height={54} project="ecg" solidFill />}
      skewOverlap={skewOverlap}
    >
      <NewFooter.LinkRow
        socialLinksSlot={
          <NewFooter.SocialLink
            href="https://www.linkedin.com/company/enercity-contracting-gmbh/?originalSubdomain=de"
            title="LinkedIn"
          >
            <LinkedInLogo height="1.25rem" />
          </NewFooter.SocialLink>
        }
        linksSlot={
          <>
            <NewFooter.Link href="/downloadcenter">Downloads</NewFooter.Link>
            <NewFooter.Link href="/unternehmen/presse">Presse</NewFooter.Link>
            <NewFooter.Link href="/unternehmen/karriere">
              Karriere
            </NewFooter.Link>
          </>
        }
      />
    </NewFooter>
  );
};

export default EcgFooter;
