import EcgContactBlock from '@/components/EcgContactBlock/EcgContactBlock';
import ComponentList from '@uikit/components/ComponentList/ComponentList';
import Container from '@uikit/components/Container/Container';
import EcgFooter from '@uikit/components/EcgFooter/EcgFooter';
import EcgHeader from '@uikit/components/EcgHeader/EcgHeader';
import Headline from '@uikit/components/Headline/Headline';
import IframeSection from '@uikit/components/IframeSection/IframeSection';
import ImageGallery from '@uikit/components/ImageGallery/ImageGallery';
import Modal from '@uikit/components/Modal/Modal';
import Section from '@uikit/components/Section/Section';
import SectionVisual from '@uikit/components/SectionVisual/SectionVisual';
import StageBoxText from '@uikit/components/StageBoxText/StageBoxText';
import TeaserWithImage from '@uikit/components/TeaserWithImage/TeaserWithImage';
import TwoColumnContainer from '@uikit/components/TwoColumnContainer/TwoColumnContainer';
import HeroArea from '@uikit/composites/HeroArea/HeroArea';
import HeroAreaContent from '@uikit/composites/HeroArea/HeroAreaContent';
import SmallHeroArea from '@uikit/composites/SmallHeroArea/SmallHeroArea';
import React from 'react';
import Forms from '../components/Forms/Forms';

export const CmsComponentsEcg = {
  'ecg-footer': EcgFooter,
  'ecg-header': EcgHeader,
  'ecg-stage-box-text': StageBoxText,
  'ecg-section': Section,
  'ecg-container': Container,
  'ecg-stage-small': SmallHeroArea,
  'ecg-hero-area-content': HeroAreaContent,
  'ecg-stage': HeroArea,
  'ecg-headline': Headline,
  'ecg-stage-area': React.Fragment,
  'ecg-section-visual': SectionVisual,
  'ecg-two-column-container': TwoColumnContainer,
  'ecg-modal': Modal,
  'ecg-iframe-section': IframeSection,
  'ecg-contact-block': EcgContactBlock,
  'ecg-image-gallery': ImageGallery,
  'ecg-download-area': React.Fragment,
  'ecg-contact-area': React.Fragment,
  'ecg-component-list': ComponentList,
  'ecg-forms': Forms,
  'ecg-teaser-with-image': TeaserWithImage,
};
